<template>
    <div align="left" justify="center" class="">
        <Loading :active="loadingInicial" :is-full-page="true" />
        <ConfirmacaoModal
            v-if="mostrarConfirmacao"
            :showDialog="mostrarConfirmacao"
            :tituloModal="tituloModal"
            :textoModal="textoModal"
            :btnConfirmarEmit="'confirmarUpload'"
            :btnConfirmar="'Ok'"
            @confirmarUpload="closeDialog(true)"
            @fecharModal="closeDialog(true)"
        />
        <div align="center" justify="center" class="pa-3">
            <v-tooltip
                v-if="mostrarBotaoFlutuante && selecionados.length !== 0"
                top
            >
                <template v-slot:activator="{ on }">
                    <v-btn
                        v-on="on"
                        fab
                        dark
                        color="green"
                        fixed
                        right
                        bottom
                        @click="dialogTemCerteza"
                    >
                        <v-icon dark>check</v-icon>
                    </v-btn>
                </template>
                <span>Enviar Arquivos</span>
            </v-tooltip>
            <v-card align="left" justify="left">
                <v-card-text class="pa-3 font-weight-light white black--text">
                    <v-icon class="primary--text lighten-2 pa-2">
                        mdi-sync </v-icon
                    >Títulos > Upload de Arquivos de Protesto em Massa
                </v-card-text>
            </v-card>
            <!-- Cabeçalho -->
            <v-card @keyup.native.enter="botaoFiltrar" class="mt-2 mb-3">
                <v-row align="center" class="mx-6 mt-3 pt-3">
                    <v-col cols="12" xs="12" sm="12" md="12" lg="8">
                        <v-card-text
                            v-bind:style="{ color: primaryColorFont }"
                            class="
                                text-left
                                font-weight-black
                                display-1
                                pl-0
                            "
                        >
                            <v-icon large class="ma-2">
                                mdi-progress-close </v-icon
                            >Upload de Arquivos de Protesto em lote
                        </v-card-text>
                        <v-textarea
                            solo
                            small
                            label="Cole aqui os títulos que deseja anexar o arquivo de protesto pendente"
                            hint="Insira nesse campo todos os títulos que deseja buscar separando por virgula, por exemplo: 477977/1, 721603/6, 755202/5"
                            placeholder="Exemplo:  477977/1, 721603/6, 755202/5"
                            clearable
                            v-model="filtros.ds_nosso_numero"
                        />
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="4" class="mt-2">
                        <v-col cols="12" sm="12" md="12" lg="12">
                            <v-card class="grey white--text white--text">
                                <v-icon class="ma-2 text-left white--text">
                                    mdi-chevron-down </v-icon
                                >fazer upload de arquivo XLS
                            </v-card>
                            <v-card-text
                                class="font-weight-light text-left title-1"
                            >
                                Utilize o campo abaixo para fazer upload de
                                arquivo com os títulos em massa para anexação do
                                arquivo de protesto. Formatos compatíveis:
                                .xls/.xlsx/.xlsm Limite de tamanho: 60mb
                            </v-card-text>
                            <v-btn
                                block
                                plain
                                small
                                class="grey white--text"
                                :href="link_modelo"
                            >
                                <v-icon class="ma-2 text-left">
                                    mdi-file-download </v-icon
                                >Baixar modelo
                            </v-btn>
                        </v-col>
                        <v-col cols="12" sm="12" class="mt-2">
                            <v-file-input
                                class="mb-n7 white--text"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xls, .xlsx, .xlsm"
                                label="Selecione o arquivo (.xls/.xlsx/.xlsm)"
                                show-size
                                chips
                                solo
                                ref="myFileInput"
                                id="fileInput"
                                name="file"
                                @change="uploadFileReference"
                            ></v-file-input>
                            <v-card-text
                                v-if="fazendoUpload"
                                class="font-weight-light text-left title-1"
                            >
                                <v-btn
                                    color="green"
                                    x-small
                                    elevation="0"
                                    fab
                                    class="ma-1"
                                    ><v-icon class="white--text"
                                        >mdi-check</v-icon
                                    ></v-btn
                                >
                                Títulos verificados:
                                {{ retornaDadosXls() }} linhas</v-card-text
                            >
                            <v-card-text
                                v-if="fazendoUpload"
                                class="text-left title-1"
                            >
                                <v-btn
                                    color="green"
                                    x-small
                                    elevation="0"
                                    fab
                                    class="ma-1"
                                    ><v-icon class="white--text"
                                        >mdi-magnify</v-icon
                                    ></v-btn
                                >
                                Para confirmar, clique em Filtrar
                            </v-card-text>
                        </v-col>
                    </v-col>
                    <v-col>
                        <v-expansion-panels>
                            <v-expansion-panel>
                                <v-expansion-panel-header
                                    small
                                    class="grey lighten-4"
                                >
                                    <v-card-text
                                        class="font-weight-light black--text"
                                    >
                                        <v-icon class="primary--text lighten-2">
                                            search
                                        </v-icon>
                                        Filtrar Resultados
                                    </v-card-text>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row class="my-3">
                                        <v-col
                                            cols="4"
                                            xs="2"
                                            sm="2"
                                            md="3"
                                            lg="3"
                                        >
                                            <v-text-field
                                                solo
                                                small
                                                label="CPF/CNPJ"
                                                hint="Informe o Número do CPF/CNPJ"
                                                placeholder="Ex.:000.000.000-00"
                                                v-model="
                                                    filtros.nu_identificacao_devedor
                                                "
                                                clearable
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="5"
                                            xs="2"
                                            sm="2"
                                            md="3"
                                            lg="3"
                                            class=""
                                        >
                                            <v-text-field
                                                solo
                                                small
                                                label="Nome"
                                                hint="Informe o nome para efetuar a busca"
                                                placeholder="Ex.: José"
                                                clearable
                                                v-model="filtros.nm_devedor"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="10"
                                            xs="2"
                                            sm="2"
                                            md="3"
                                            lg="3"
                                        >
                                            <v-select
                                                solo
                                                small
                                                v-model="filtros.cd_uf_devedor"
                                                :items="listaUfs"
                                                label="UF"
                                                :return-object="true"
                                            ></v-select>
                                        </v-col>
                                        <v-col
                                            cols="4"
                                            xs="2"
                                            sm="2"
                                            md="3"
                                            lg="3"
                                        >
                                            <v-checkbox
                                                class="
                                                    ml-6
                                                    mt-3
                                                    d-flex
                                                    align-center
                                                    justify-center
                                                "
                                                color="primary"
                                                v-model="oferta"
                                                label="Renegociação"
                                            ></v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <v-row class="my-3">
                                        <v-col
                                            v-if="!isUsuarioAgrupador"
                                            cols="4"
                                            xs="2"
                                            sm="2"
                                            md="3"
                                            lg="3"
                                        >
                                            <v-select
                                                solo
                                                dense
                                                :disabled="
                                                    loadingAgrupador ||
                                                        usuarioAgrupador != null
                                                "
                                                :loading="loadingAgrupador"
                                                :items="agrupadores"
                                                v-model="filtros.ds_agrupador"
                                                label="Agrupador"
                                                item-text="nm_agrupador"
                                                item-value="ds_agrupador"
                                                data-cy="agrupador"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                </v-row>

                <v-row class="mx-0 mt-2 mb-0 pa-0">
                    <v-spacer></v-spacer>
                    <v-col
                        cols="10"
                        xs="6"
                        sm="6"
                        md="3"
                        lg="3"
                        class="pr-9"
                        align="center"
                    >
                        <v-btn
                            block
                            class="d-flex justify-center"
                            color="primary"
                            :loading="loadingFiltros"
                            @click="botaoFiltrar"
                        >
                            Filtrar
                            <v-icon class="ml-2">search</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
            <!-- fim do cabeçalho -->

            <!-- Tabela com os titulos a receber anexo de arquivo -->
            <v-dialog
                v-if="temCerteza"
                v-model="temCerteza"
                max-width="1000px"
                persistent
                scrollable
                color="background"
                transition="dialog-bottom-transition"
            >
                <v-card>
                    <v-toolbar flat dark color="primary">
                        <v-btn icon dark @click="closeDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>

                        <v-toolbar-title v-if="titulosEnviadosCheck">
                            Upload de Arquivos de Protesto
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-divider></v-divider>
                    <v-card-text
                        min-height="200px"
                        style="border-radius: 0"
                        class="py-5"
                        v-if="titulosEnviadosCheck"
                    >
                        <v-list shaped class="pa-0">
                            <v-subheader
                                class="primary--text title font-weight-black"
                                >Número de Títulos selecionados:
                                {{ titulosSelecionadosQtd() }}
                            </v-subheader>
                            <div class="px-4 my-3">
                                Os Títulos abaixo foram selecionados para
                                anexação de arquivo de protesto. O arquivo
                                correspondente a cada título deve estar
                                <b>nomeado com o mesmo número do título</b> para
                                ser considerado válido. Além disso, somente
                                arquivos no <b>formato PDF</b> são permitidos.
                            </div>
                            <v-row class="ma-0">
                                <v-col
                                    cols="4"
                                    xs="4"
                                    sm="4"
                                    md="4"
                                    lg="4"
                                    class="font-weight-bold"
                                >
                                    Título
                                </v-col>
                                <v-col
                                    cols="6"
                                    xs="6"
                                    sm="6"
                                    md="6"
                                    lg="6"
                                    class="font-weight-bold"
                                >
                                    Arquivo(s) anexado(s)
                                </v-col>
                                <v-col
                                    cols="2"
                                    xs="2"
                                    sm="2"
                                    md="2"
                                    lg="2"
                                    class="font-weight-bold"
                                >
                                </v-col>
                            </v-row>
                            <v-list-item
                                v-for="(titulo, i) in listaTitulosAnexo"
                                :key="i"
                                shaped
                                class="px-0"
                            >
                                <v-row class="ma-0">
                                    <v-col
                                        cols="4"
                                        xs="4"
                                        sm="4"
                                        md="4"
                                        lg="4"
                                        class="pa-0 d-flex justify-center align-center"
                                    >
                                        <v-card-text class="bold py-3">
                                            <v-icon class="ma-1"
                                                >mdi-information</v-icon
                                            >Título:
                                            {{ titulo.ds_nosso_numero }}
                                        </v-card-text>
                                    </v-col>
                                    <v-col
                                        cols="6"
                                        xs="6"
                                        sm="6"
                                        md="6"
                                        lg="6"
                                        class="pa-0 d-flex justify-center align-center"
                                    >
                                        <v-card-text class="bold py-3">
                                            {{ formatarNomesArquivos(titulo) }}
                                            <v-tooltip
                                                v-if="
                                                    titulo.arquivos_selecionados &&
                                                        titulo
                                                            .arquivos_selecionados
                                                            .length
                                                "
                                                top
                                            >
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs
                                                    }"
                                                >
                                                    <v-icon
                                                        class="ma-3"
                                                        :color="
                                                            tamanhoArquivosValido(
                                                                titulo.arquivos_selecionados
                                                            )
                                                                ? 'green'
                                                                : 'red'
                                                        "
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                        {{
                                                            tamanhoArquivosValido(
                                                                titulo.arquivos_selecionados
                                                            )
                                                                ? 'done'
                                                                : 'error'
                                                        }}
                                                    </v-icon>
                                                </template>
                                                <span>
                                                    {{
                                                        tamanhoArquivosValido(
                                                            titulo.arquivos_selecionados
                                                        )
                                                            ? 'Os arquivos selecionados estão dentro do tamanho máximo permitido de 5MB por Título'
                                                            : 'A soma do tamanho dos arquivos não pode ultrapassar 5MB por Título'
                                                    }}
                                                </span>
                                            </v-tooltip>
                                        </v-card-text>
                                    </v-col>
                                    <v-col
                                        cols="2"
                                        xs="2"
                                        sm="2"
                                        md="2"
                                        lg="2"
                                        class="pa-0 d-flex justify-center align-center"
                                    >
                                        <v-card-text
                                            class="py-3 d-flex justify-center align-center"
                                        >
                                            <v-btn
                                                @click="
                                                    document
                                                        .getElementById(
                                                            `pdfFileInput${i}`
                                                        )
                                                        .click()
                                                "
                                                >Selecionar</v-btn
                                            >
                                            <v-file-input
                                                v-model="
                                                    titulo.arquivos_selecionados
                                                "
                                                :accept="
                                                    'application/pdf, application/zip'
                                                "
                                                hide-input
                                                prepend-icon=""
                                                multiple
                                                :id="`pdfFileInput${i}`"
                                                @change="$forceUpdate()"
                                            ></v-file-input>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions
                        v-if="titulosEnviadosCheck"
                        class="justify-end"
                    >
                        <v-btn
                            class="ma-2"
                            color="success"
                            @click="uploadArquivosMassa"
                            :loading="loadingFiltros"
                            :disabled="
                                verificarPreenchimentoTitulos(listaTitulosAnexo)
                            "
                        >
                            <v-icon> mdi-chevron-right</v-icon>
                            Enviar</v-btn
                        ><v-btn
                            class="ma-2"
                            @click="closeDialog"
                            :loading="loadingFiltros"
                            >Cancelar</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <Snackbar
                v-if="snackbarDialog"
                :mostrarSnackbar="snackbarDialog"
                :corSnackbar="snackbarColor"
                :mensagemSnackbar="mensagemRetornoSnackbar"
                @fecharSnackbar="snackbarDialog = false"
            />

            <v-data-table
                :headers="headers"
                :items="titulos"
                item-text="titulo"
                v-model="selecionados"
                :options.sync="options"
                :loading="loading"
                :single-select="singleSelect"
                item-key="id_titulo"
                show-select
                :footer-props="{
                    itemsPerPageOptions: [50, 1000, 5000, -1],
                    itemsPerPageText: 'Itens por página:'
                }"
                :server-items-length="totalTitulos"
                loading-text="Pesquisando títulos..."
                no-data-text="Preencha os filtros e clique em filtrar..."
                no-results-text="Nenhum título encontrado..."
                class="elevation-1 pa-3"
            >
                <template v-slot:[`item.id_titulo`]="{ item }">
                    <span
                        v-if="item.ds_revisao != ''"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ item.id_titulo }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        item.id_titulo
                    }}</span>
                </template>
                <template v-slot:[`item.ds_nosso_numero`]="{ item }">
                    <span
                        v-if="item.ds_revisao != ''"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ item.ds_nosso_numero }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        item.ds_nosso_numero
                    }}</span>
                </template>
                <template v-slot:[`item.dt_cadastro`]="{ item }">
                    <span
                        v-if="item.ds_revisao != ''"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ item.dt_cadastro }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        item.dt_cadastro
                    }}</span>
                </template>
                <template v-slot:[`item.dt_vencimento`]="{ item }">
                    <span
                        v-if="item.ds_revisao != ''"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ formatDataVencimento(item) }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        formatDataVencimento(item)
                    }}</span>
                </template>
                <template v-slot:[`item.nu_identificacao_devedor`]="{ item }">
                    <span
                        v-if="item.ds_revisao != ''"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ formatDocumento(item) }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        formatDocumento(item)
                    }}</span>
                </template>
                <template v-slot:[`item.nm_devedor`]="{ item }">
                    <span
                        v-if="item.ds_revisao != ''"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ item.nm_devedor }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        item.nm_devedor
                    }}</span>
                </template>
                <template v-slot:[`item.vl_saldo_protesto`]="{ item }">
                    <span
                        v-if="item.ds_revisao != ''"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ item.vl_saldo_protesto }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        item.vl_saldo_protesto
                    }}</span>
                </template>
                <template v-slot:[`item.cd_status`]="{ item }">
                    <span
                        v-if="item.ds_revisao != ''"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ getDescSituacao(item) }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        getDescSituacao(item)
                    }}</span>
                </template>
                <template v-slot:[`item.dt_atualizacao`]="{ item }">
                    <span
                        v-if="item.ds_revisao != ''"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ item.dt_atualizacao }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        item.dt_atualizacao
                    }}</span>
                </template>
                <template v-slot:[`item.cd_uf_devedor`]="{ item }">
                    <span
                        v-if="item.ds_revisao != ''"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ item.cd_uf_devedor }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        item.cd_uf_devedor
                    }}</span>
                </template>
                <template v-slot:[`item.cd_especie_titulo`]="{ item }">
                    <span
                        v-if="item.ds_revisao != ''"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ item.cd_especie_titulo }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        item.cd_especie_titulo
                    }}</span>
                </template>
            </v-data-table>
            <v-card class="mt-3">
                <v-row class="ma-0">
                    <v-spacer></v-spacer>
                    <v-col sm="6" md="3" lg="4" class="d-flex justify-end pr-9">
                        <v-tooltip top :disabled="selecionados.length !== 0">
                            <template v-slot:activator="{ on }">
                                <div v-on="on">
                                    <v-btn
                                        class="d-flex justify-center"
                                        color="green white--text"
                                        @click="dialogTemCerteza"
                                        :disabled="!selecionados.length"
                                        v-intersect="onIntersect"
                                    >
                                        Enviar Arquivos
                                        <v-icon class="ml-2"
                                            >mdi-chevron-right</v-icon
                                        >
                                    </v-btn>
                                </div>
                            </template>
                            <span
                                >Selecione um ou mais títulos para liberar o
                                comando</span
                            >
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-card>
            <!-- fim da tabela com os titulos -->

            <v-dialog
                v-if="dialogNaoPermitido"
                v-model="dialogNaoPermitido"
                max-width="800px"
                persistent
                scrollable
                color="background"
                transition="dialog-bottom-transition"
            >
                <v-card>
                    <v-card-title>
                        <v-icon large class="ma-2"> mdi-information </v-icon
                        >Ação não permitida.
                    </v-card-title>
                    <v-card-text>
                        Para realizar ações de Upload de Arquivos de Protesto em
                        Lote,
                        <strong>entre em contato com nosso suporte</strong>
                        através do email:
                        <a href="mailto:suporte@recuperi.com.br"
                            >suporte@recuperi.com.br</a
                        >, nossa equipe irá auxiliar nessa ação.
                    </v-card-text>
                    <v-card-actions class="d-flex justify-end my-3">
                        <v-btn
                            color="primary"
                            class="white--text d-flex justify-center pl-5"
                            @click="dialogNaoPermitido = false"
                        >
                            Fechar
                            <v-icon large>
                                mdi-chevron-right
                            </v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import TituloService from '@/services/TituloService';
import * as Formatter from '@/helpers/Formatter';
import XLSX from 'xlsx';
import ConfirmacaoModal from '@/modals/ConfirmacaoModal';
import {
    getMensagemRequest,
    listarAgrupadores,
    getDescritivoAgrupador,
    montarObjetoAnexoProtesto
} from '@/helpers/Utilitarios';
import Loading from 'vue-loading-overlay';
import router from '@/router';

export default {
    name: 'UploadEmMassa',
    components: {
        Loading,
        ConfirmacaoModal
    },
    data() {
        return {
            document,
            tituloService: new TituloService(Vue.http, this.$store),
            mostrarConfirmacao: false,
            tituloModal: 'Resultado do Upload',
            textoModal: '',
            mostrarBotaoFlutuante: true,
            snackbarTimeout: '4000',
            snackbarColor: 'green',
            snackbarDialog: false,
            mensagemRetornoSnackbar: 'Teste',
            dialogNaoPermitido: false,
            botaoRelatorioXls: null,
            json_fields: {
                ID: 'id',
                Código: 'retorno.codigo',
                Mensagem: 'retorno.mensagem'
            },
            tamanhoMaximoSomaArquivos: 5000000,
            link_modelo: '/Planilha-atualizacao-em-massa-Recuperi.xlsx',
            revisao: false,
            oferta: false,
            dialogLoading: false,
            filtrar: false,
            selecionados: [],
            avisoRetorno: [],
            temCerteza: false,
            titulosEnviadosCheck: true,
            dialog: false,
            singleSelect: false,
            ultimoFiltro: null,
            exportActive: true,
            registros_pagina: 10,
            numero_paginas: 1,
            showPagination: false,
            loadingFiltros: false,
            situacoesTitulo: [],
            totalTitulos: 0,
            titulos: [],
            loading: false,
            loadingInicial: false,
            options: {
                page: 1,
                itemsPerPage: 50
            },
            mensagem: null,
            primaryColorFont: '#757575',
            listaUfs: [
                'AC',
                'AL',
                'AP',
                'AM',
                'BA',
                'CE',
                'DF',
                'ES',
                'GO',
                'MA',
                'MT',
                'MS',
                'MG',
                'PA',
                'PB',
                'PR',
                'PE',
                'PI',
                'RJ',
                'RN',
                'RS',
                'RO',
                'RR',
                'SC',
                'SP',
                'SE',
                'TO'
            ],
            filtros: {
                cd_status: null,
                nm_devedor: null,
                ds_revisao: 'AP',
                cd_carteira: 'Todos',
                nu_identificacao_devedor: null,
                ds_nosso_numero: null,
                ds_numero_titulo: null,
                ds_agrupador: null,
                apontamento_nu_protocolo: null,
                dt_vencimento_range: [],
                dt_cadastro_range: [],
                dt_atualizacao_range: [],
                dt_vencimento_range_fmt: null,
                dt_atualizacao_range_fmt: null,
                dt_cadastro_range_fmt: null,
                uploadOk: false,
                loadingUpload: false,
                desabilitaBoxEnvio: false
            },
            file: null,
            customerCountRead: 0,
            dadosXls: null,
            fazendoUpload: false,
            headers: [
                {
                    text: 'Código do título',
                    sortable: true,
                    value: 'ds_nosso_numero'
                },
                {
                    text: 'Data de envio',
                    align: 'left',
                    sortable: true,
                    value: 'dt_cadastro'
                },
                {
                    text: 'CPF/CNPJ',
                    sortable: true,
                    value: 'nu_identificacao_devedor'
                },
                { text: 'Nome', sortable: true, value: 'nm_devedor' },
                {
                    text: 'Valor R$',
                    sortable: true,
                    value: 'vl_saldo_protesto'
                },
                { text: 'Status', sortable: true, value: 'cd_status' },
                {
                    text: 'Data de atualização',
                    align: 'left',
                    sortable: true,
                    value: 'dt_atualizacao'
                },
                { text: 'UF', sortable: true, value: 'cd_uf_devedor' },
                { text: 'Espécie', sortable: true, value: 'cd_especie_titulo' },
                { text: ' ', sortable: true, value: 'action' }
            ],
            loadingAgrupador: false
        };
    },
    watch: {
        pagina() {
            this.aplicarFiltros();
            this.dialogLoading = false;
        },
        options: {
            handler() {
                if (this.filtrar) {
                    this.aplicarFiltros();
                }
            },
            deep: true
        },
        temCerteza(val) {
            if (val) {
                document.addEventListener('keyup', this.eventFecharModal);
            } else {
                document.removeEventListener('keyup', this.eventFecharModal);
            }
        },
        mostrarConfirmacao(val) {
            if (val) {
                document.removeEventListener('keyup', this.eventFecharModal);
            } else {
                document.addEventListener('keyup', this.eventFecharModal);
            }
        }
    },
    computed: {
        usuarioAgrupador() {
            return this.$store.getters.agrupadorUsuario;
        },
        agrupadores() {
            return this.$store.getters.listaAgrupadoresFiltro;
        },
        isUsuarioAgrupador() {
            return this.$store.getters.isUsuarioAgrupador;
        },
        usuarioAdmin() {
            if (this.$store.getters.isAdmin == 1) {
                return true;
            }
            return false;
        },
        verificaPermissaoAnexo() {
            if (this.$store.getters.isBasicUser 
                || this.$store.getters.isAdmin
                || this.$store.getters.isSuperUser) {
                return true;
            }
            return false;
        
        },
        isApresentanteCraNacional() {
            return this.$store.getters.isApresentanteCraNacional;
        }
    },
    async mounted() {
        if (!this.isApresentanteCraNacional) {
            router.push('/');
        }

        this.filtros.dt_vencimento_de = this.filtros.dt_vencimento_range[0];
        this.filtros.dt_vencimento_ate = this.filtros.dt_vencimento_range[1];
        this.filtros.dt_cadastro_de = this.filtros.dt_cadastro_range[0];
        this.filtros.dt_cadastro_ate = this.filtros.dt_cadastro_range[1];
        this.filtros.dt_atualizacao_de = this.filtros.dt_atualizacao_range[0];
        this.filtros.dt_atualizacao_ate = this.filtros.dt_atualizacao_range[1];

        if (!this.verificaPermissaoAnexo) {
            this.dialogNaoPermitido = true;
        }

        this.loadingInicial = true;
        this.loadingInicial = false;

        this.situacoesTitulo = this.$store.getters.situacoesTitulo;
        if (this.usuarioAgrupador) {
            this.filtros.ds_agrupador = this.usuarioAgrupador;
        }

        if (this.$store.getters.isPartner == 1) {
            this.primaryColorFont = '#757575';
        } else {
            this.primaryColorFont = this.$vuetify.theme.themes.light.primary;
        }

        await this.checarAgrupadores();
    },
    methods: {
        getMensagemRequest,
        listarAgrupadores,
        getDescritivoAgrupador,
        montarObjetoAnexoProtesto,
        onIntersect(entries) {
            const isIntersecting = entries[0].isIntersecting;
            this.mostrarBotaoFlutuante = !isIntersecting;
        },
        async checarAgrupadores() {
            try {
                this.loadingAgrupador = true;
                await this.listarAgrupadores(this.$store, true);
            } catch (e) {
                this.mostrarSnackbar(
                    'error',
                    'Erro ao buscar Agrupadores disponíveis.'
                );
            } finally {
                this.loadingAgrupador = false;
            }
        },
        eventFecharModal(e) {
            if (!e) e = window.event;
            const keyCode = e.code || e.key;

            if (keyCode === 'Escape') {
                if (this.temCerteza) {
                    this.closeDialog();
                }
            }
        },
        botaoFiltrar() {
            if (this.verificaPermissaoAnexo) {
                this.filtrar = true;
                this.ultimoFiltro = this.filtros;
                this.aplicarFiltros();
            } else {
                this.dialogNaoPermitido = true;
            }
        },
        formatDocumento(titulo) {
            return Formatter.formatDocumento(
                titulo.nu_identificacao_devedor,
                titulo.tp_identificacao_devedor
            );
        },
        formatDataVencimento(titulo) {
            return Formatter.formatData(titulo.dt_vencimento);
        },
        getDescSituacao(titulo) {
            return Formatter.getDescSituacaoTitulo(
                titulo.cd_status,
                this.situacoesTitulo
            );
        },
        aplicarFiltros() {
            if (this.oferta == true) {
                this.filtros.oferta = '1';
            } else {
                this.filtros.oferta = '';
            }
            if (this.dadosXls == null) {
                this.filtros.ds_nosso_numero = this.filtros.ds_nosso_numero;
            } else {
                this.filtros.ds_nosso_numero = this.dadosXls.toString();
            }
            this.buscarTitulos().then(data => {
                this.titulos = data.titulos;
                this.totalTitulos = data.total;
                if (data.total === 0) {
                    this.mensagem = 'A Consulta não retornou nenhum titulo.';
                    this.numero_paginas = 1;
                } else {
                    this.mensagem = null;
                    this.numero_paginas =
                        data.total % this.registros_pagina == 0
                            ? data.total / this.registros_pagina
                            : parseInt(data.total / this.registros_pagina) + 1;
                }
                this.showPagination = this.numero_paginas > 1;
            });
        },
        buscarTitulos() {
            const page = this.options.page;
            const itemsPerPage = this.options.itemsPerPage;
            this.situacoesTitulo = this.$store.getters.situacoesTitulo;
            this.situacoesTitulo.push({
                cd_status: 99999,
                nm_status: 'EM REVISÃO'
            });
            if (this.ultimoFiltro == null) {
                this.ultimoFiltro = this.filtros;
            }
            this.ultimoFiltro.nu_identificacao_devedor = this.formatarDocumento();
            return new Promise((resolve, reject) => {
                this.loadingFiltros = true;
                this.loading = true;

                try {
                    let service = new TituloService(Vue.http, this.$store);

                    service
                        .filtrarTitulos(this.ultimoFiltro, page, itemsPerPage)
                        .then(
                            response => {
                                response.headers.get('Expires');
                                this.exportActive = false;
                                const {
                                    titulos,
                                    numero_linhas: total
                                } = response.body;

                                this.loadingFiltros = false;
                                this.loading = false;
                                resolve({
                                    titulos,
                                    total
                                });
                            },
                            error => {
                                this.loadingFiltros = false;
                                this.loading = false;
                                this.mensagem = error;
                                console.log(error);
                            }
                        );
                } catch (e) {
                    this.mensagem = e;
                    console.log(e);
                    reject();
                }
            });
        },
        verificarPreenchimentoTitulos() {
            if (
                !this.titulosSemArquivoSelecionado() &&
                !this.titulosComArquivoTamanhoExcedente()
            ) {
                return false;
            }
            return true;
        },
        titulosSemArquivoSelecionado() {
            const titulosSemArquivo = this.listaTitulosAnexo.some(
                titulo =>
                    !titulo.arquivos_selecionados ||
                    !titulo.arquivos_selecionados.length
            );
            return titulosSemArquivo;
        },
        titulosComArquivoTamanhoExcedente() {
            const tituloTamanhoExcedente = this.listaTitulosAnexo.some(
                titulo =>
                    !this.tamanhoArquivosValido(titulo.arquivos_selecionados)
            );
            return tituloTamanhoExcedente;
        },
        tamanhoArquivosValido(arquivos) {
            const tamanhoArquivos = arquivos.reduce(
                (acumulador, file) => acumulador + file.size,
                0
            );
            if (tamanhoArquivos > this.tamanhoMaximoSomaArquivos) {
                return false;
            }
            return true;
        },
        formatarNomesArquivos(titulo) {
            if (titulo?.arquivos_selecionados?.length > 0) {
                const arquivos = titulo.arquivos_selecionados.map(
                    arquivo => arquivo.name
                );
                return arquivos.join(', ');
            }
            return '--';
        },
        tratarArquivos() {
            this.$forceUpdate();
        },
        titulosComErro(listaTitulosAnexo) {
            for (let index = 0; index < listaTitulosAnexo.length; index++) {
                const titulo = listaTitulosAnexo[index];
                if (
                    !titulo.arquivos_selecionados ||
                    !this.tamanhoArquivosValido(titulo.arquivos_selecionados)
                ) {
                    return true;
                }
            }

            return false;
        },
        async uploadArquivosMassa() {
            try {
                this.loadingFiltros = true;
                const listaAnexos = [];
                for (
                    let index = 0;
                    index < this.listaTitulosAnexo.length;
                    index++
                ) {
                    const tituloAnexo = this.listaTitulosAnexo[index];
                    const objetoAnexo = await this.montarObjetoAnexoProtesto(
                        tituloAnexo.ds_nosso_numero,
                        tituloAnexo.arquivos_selecionados
                    );
                    listaAnexos.push(objetoAnexo);
                }

                const response = await this.tituloService.anexarArquivoLote(
                    listaAnexos
                );

                this.montarResultadoUpload(response);
            } catch (error) {
                const mensagem = this.getMensagemRequest(
                    error.body,
                    'Erro ao anexar Arquivos'
                );
                this.mostrarSnackbar('error', mensagem);
            } finally {
                this.loadingFiltros = false;
            }
        },
        montarResultadoUpload(response) {
            const titulosSucesso =
                response.titulos_atualizados.length > 0
                    ? response.titulos_atualizados.join(', ')
                    : 'Nenhum';
            const titulosFalha =
                response.titulos_nao_atualizados.length > 0
                    ? response.titulos_nao_atualizados.join(', ')
                    : 'Nenhum';

            this.textoModal = `Títulos atualizados com sucesso: ${titulosSucesso}. <br /><br /> Títulos não atualizados: ${titulosFalha}`;
            this.mostrarConfirmacao = true;
        },
        mostrarSnackbar(snackbarColor, mensagemRetornoSnackbar) {
            this.snackbarColor = snackbarColor;
            this.snackbarDialog = true;
            this.mensagemRetornoSnackbar = mensagemRetornoSnackbar;
        },
        titulosSelecionadosQtd() {
            const dados = this.selecionados.length;
            return dados;
        },
        closeDialog(reload = false) {
            this.temCerteza = false;
            this.mostrarConfirmacao = false;

            if (reload) {
                this.selecionados = [];
                this.botaoFiltrar();
            }
        },
        dialogTemCerteza() {
            this.listaTitulosAnexo = this.selecionados.map(titulo => ({
                ds_nosso_numero: titulo.ds_nosso_numero,
                arquivos_selecionados: null
            }));
            this.temCerteza = true;
        },
        formatarDocumento() {
            var nu_identificacao_devedor = this.ultimoFiltro
                .nu_identificacao_devedor;
            if (nu_identificacao_devedor != null) {
                nu_identificacao_devedor = nu_identificacao_devedor.match(
                    /\d/g
                );
                nu_identificacao_devedor = nu_identificacao_devedor.join('');
                return nu_identificacao_devedor;
            } else {
                return null;
            }
        },
        uploadFileReference(e) {
            if (!e) return null;
            this.file = e;
            this.uploadOk = false;
            const reader = new FileReader();
            var vm = this;
            reader.onload = function(e) {
                var data = e.target.result;
                var workbook = XLSX.read(data, { type: 'binary' });
                let sheetName = workbook.SheetNames[0];
                let worksheet = workbook.Sheets[sheetName];
                let rowObject = XLSX.utils.sheet_to_row_object_array(worksheet);
                const finalJsonData = JSON.stringify(rowObject, undefined, 4);
                let rows = JSON.parse(finalJsonData);
                vm.customerCountRead = rows.length;
                vm.dadosXls = rows.map(e => e.ds_nosso_numero);
            };
            reader.readAsBinaryString(this.file);
            this.fazendoUpload = true;
        },
        retornaDadosXls() {
            return this.customerCountRead;
        }
    }
};
</script>
